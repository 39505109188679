@font-face {
    font-family: "iconfont";
    /* Project id 4475476 */
    src: url('//at.alicdn.com/t/c/font_4475476_t6k5wjx1oqc.woff2?t=1712046562055') format('woff2'),
        url('//at.alicdn.com/t/c/font_4475476_t6k5wjx1oqc.woff?t=1712046562055') format('woff'),
        url('//at.alicdn.com/t/c/font_4475476_t6k5wjx1oqc.ttf?t=1712046562055') format('truetype'),
        url('//at.alicdn.com/t/c/font_4475476_t6k5wjx1oqc.svg?t=1712046562055#iconfont') format('svg');
}

.iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-guanbi:before {
    content: "\e621";
}

.icon-github:before {
    content: "\e600";
}

.icon-discord:before {
    content: "\e6e0";
}

.icon-gitbook:before {
    content: "\e611";
}

.icon-twitter:before {
    content: "\e601";
}

.icon-down:before {
    content: "\e606";
}

.icon-add:before {
    content: "\e6cd";
}

.icon-jian_sekuai:before {
    content: "\ed21";
}

.icon-user:before {
    content: "\e66c";
}

.icon-menu:before {
    content: "\e60b";
}