@tailwind base;
@tailwind components;
@tailwind utilities;

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

div {
    line-height: 1;
}


.leading-point-155 {
    line-height: 155%;
}

.leading-point-114 {
    line-height: 114%;
}

.leading-point-180 {
    line-height: 180%;
}

.leading-point-140 {
    line-height: 140%;
}

.leading-point-161 {
    line-height: 161%;
}

.leading-point-127 {
    line-height: 127%;
}

.leading-point-126 {
    line-height: 126%;
}

.leading-point-121 {
    line-height: 121%;
}

.leading-point-151 {
    line-height: 151%;
}

.leading-point-139 {
    line-height: 139%;
}

.leading-num-24 {
    line-height: 24;
}

.jump-button {
    @apply rounded-lg w-15-7 h-3-5 text-1-1 lg:w-20-5 lg:h-5-0 lg:text-2-0;
}

input {
    outline: none;
}

.green-module-bg {
    background: rgb(47, 205, 130);
    background: linear-gradient(180deg, rgba(47, 205, 130, 0.19) 0%, rgba(217, 217, 217, 0) 100%);
}



.image-bg:before {
    content: "";
    display: block;

}

.image-bg-tp-120 {
    padding-bottom: 120%;
}

.image-bg-tp-760 {
    padding-bottom: 760%;
}

.image-bg-tp-157 {
    padding-bottom: 157%;
    /* border: 1px solid red; */
}


.image-bg-tp-247 {
    padding-bottom: 247%;
}

.image-bg-tp-1025 {
    padding-bottom: 1025%;
}

.image-bg-tp-920 {
    padding-bottom: 920%;
}

.image-bg-tp-220 {
    padding-bottom: 220%;
}

.image-bg-tp-219 {
    padding-bottom: 219%;
}

.image-bg-tp-199 {
    padding-bottom: 199%;
}

.image-bg-tp-53 {
    padding-bottom: 53%;
    /* border: 1px solid blue; */
}

.image-bg-tp-67 {
    padding-bottom: 67%;
    /* border: 1px solid blue; */
}

.image-bg-tp-160 {
    padding-bottom: 160%;
    /* border: 1px solid blue; */
}

.image-bg-tp-127 {
    padding-bottom: 127%;
}

.image-bg-tp-1248 {
    padding-bottom: 1248%;
    /* border: 1px solid red; */
}

.image-bg-tp-1371 {
    padding-bottom: 1371%;
    /* border: 1px solid red; */
}

.image-bg-tp-1678 {
    padding-bottom: 1678%;
    /* border: 1px solid red; */
}

.image-bg-tp-170 {
    padding-bottom: 170%;
}

.image-bg-tp-290 {
    padding-bottom: 290%;
}

.why-button-group {
    top: 18%;
}


.how-button-group {
    top: 19.4%;
}

.ellipse-1 {
    position: absolute;
    top: 1240px;

}

.text-gradient {
    background-image: linear-gradient(90deg, rgba(47, 205, 130, 1) 0%, rgba(236, 101, 43, 1) 54%, rgba(47, 205, 130, 1) 95%);
    -webkit-background-clip: text;
    color: transparent;
}

.green-button {
    text-align: center;
    background-color: #2FCD82;
    color: white;
    cursor: pointer;
    @apply flex justify-center items-center font-black;
}

.green-button:hover {
    -webkit-box-shadow: 1px 0px 25px 0px rgba(47, 205, 103, 0.75);
    -moz-box-shadow: 1px 0px 25px 0px rgba(47, 205, 103, 0.75);
    box-shadow: 1px 0px 25px 0px rgba(47, 205, 103, 0.75);
    background: transparent;
    border: 2px solid rgb(47, 205, 103);
    color: rgb(47, 205, 103);
    transition: 0.3s;
}

.orange-button {
    text-align: center;
    background-color: #EC652B;
    color: white;
    cursor: pointer;
    @apply flex justify-center items-center font-black;
}

.orange-button:hover {
    -webkit-box-shadow: 1px 0px 25px 0px rgba(236, 101, 43, 0.75);
    -moz-box-shadow: 1px 0px 25px 0px rgba(236, 101, 43, 0.75);
    box-shadow: 1px 0px 25px 0px rgba(236, 101, 43, 0.75);
    background: transparent;
    border: 2px solid rgb(236, 101, 43);
    color: rgb(236, 101, 43);
    transition: 0.3s;
}

@keyframes dong {
    0% {
        transform: translate(0px, 0px);
    }

    50% {
        transform: translate(0px, -10px);
    }

    100% {
        transform: translate(0px, 0px);
    }
}

.top {
    animation: dong 4s infinite;
}

.menu-item {
    /* border: 1px solid red; */
}

.menu-item:hover {
    color: #2FCD82;
    /* background: #000; */
}

.mobile-active-item:active {
    color: #000;
    background: rgb(248 250 252);
}


.pc-dev-card-bg {
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 17%, rgba(0, 0, 0, 0) 100%);
}

/* animate */
.trans {
    transition: all 3s 2s linear;
}

.title-shadow-green {
    text-shadow: 4px 4px 10px #0D5157;
}

.title-shadow-game {
    text-shadow: 4px 4px 10px #144477;
}

.box-shadow-green {
    -webkit-box-shadow: 1px 0px 20px 0px rgba(47, 205, 130, 0.2);
    -moz-box-shadow: 1px 0px 20px 0px rgba(47, 205, 130, 0.2);
    box-shadow: 1px 0px 20px 0px rgba(47, 205, 130, 0.2);
    /* background: rgba(255, 255, 255, 0.4); */
}

.box-shadow-game {
    background: rgb(0, 61, 108);
    background: linear-gradient(90deg, rgba(0, 61, 108, 1) 0%, rgba(188, 116, 206, 1) 53%, rgba(236, 66, 43, 1) 100%);
}

.box-shadow-digital {
    background: rgb(38, 122, 102);
    background: linear-gradient(90deg, rgba(38, 122, 102, 1) 0%, rgba(52, 52, 122, 1) 100%);
}

/* .title-shadow {
    text-shadow: #0D5157 10px 0 0, #0D5157 0 10px 0, #000 -10px 0 0, #0D5157 0 -10px 0;
} */